:root .sl-theme-dark{
  /* Changes the primary theme color to purple using primitives */
  --sl-color-primary-50: var(--sl-color-emerald-50);
  --sl-color-primary-100: var(--sl-color-emerald-100);
  --sl-color-primary-200: var(--sl-color-emerald-200);
  --sl-color-primary-300: var(--sl-color-emerald-300);
  --sl-color-primary-400: var(--sl-color-emerald-400);
  --sl-color-primary-500: var(--sl-color-emerald-500);
  --sl-color-primary-600: var(--sl-color-emerald-600);
  --sl-color-primary-700: var(--sl-color-emerald-700);
  --sl-color-primary-800: var(--sl-color-emerald-800);
  --sl-color-primary-900: var(--sl-color-emerald-900);
  --sl-color-primary-950: var(--sl-color-emerald-950);
}

body{
  background-color: var(--sl-color-neutral-50);

  background-image: url('../public/bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#app{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* width:100vw; */
  /* background-color: var(--sl-color-neutral-50); */
  color:#f0f0f0;
  margin:0;
  padding:0;
  font-family: inherit;
}

.wizard div[slot="footer"] sl-button{
  min-width:10rem;
}


#appHeader{
  position:relative;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
  height:4rem;
  background-color:transparent;
  border-bottom:1px solid var(--sl-color-primary-100);
  padding:0 1rem;
  box-sizing: border-box;
  /* overflow:hidden; */
}
#appHeader.loading:after{
  content: '';
  position: absolute;
  bottom: -1px; /* Align it with the bottom border */
  left: 0;
  width: 6px; /* Size of the dot */
  height: 1px; /* Size of the dot */
  background-color:var(--sl-color-primary-600); /* Color of the dot */
  animation: travelAlong 3s linear infinite; /* Animation */
}
.welcome-arrow{
  font-size:8rem;
  text-align: left;
  color:var(--sl-color-primary-100);
  margin-right:calc(100% - 10rem);
  animation: bounceLeftRight 0.5s ease-in infinite;
}
@keyframes bounceLeftRight{
  0%,100%{
    margin-right:calc(100% - 10rem);
  }
  50%{
    margin-right:calc(100% - 12rem);
  }
}
@keyframes travelAlong {
  0%,100%{
    left: 0;
    background-image: linear-gradient(to right, 
    var(--sl-color-primary-100), 
    var(--sl-color-primary-600), 
    var(--sl-color-primary-100));    width: 1%; /* Size of the dot */

  }
  25%,75%{
      left: 50%;
      background-image: linear-gradient(to right, 
      var(--sl-color-primary-100), 
      var(--sl-color-primary-600), 
      var(--sl-color-primary-100));
      width: 25%; /* Size of the dot */

       }
   50% {
      left: 100%;
      background-image: linear-gradient(to right, 
      var(--sl-color-primary-100), 
      var(--sl-color-primary-600), 
      var(--sl-color-primary-100));      width: 1%; /* Size of the dot */

     }
 
  
}

#appHeader h1{
  font-size:1.5rem;
  color:var(--sl-color-neutral-700);
  text-transform: uppercase;

  margin:0;
  padding:0;
  margin-right:1rem;
  font-weight:bold;
  letter-spacing: 0.09em;;
}
#appHeader #toolbar{
  /* color:var(--sl-color-primary-500);
  border-color:var(--sl-color-primary-500); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
#appHeader #toolbar sl-icon-button::part(base){
  color:var(--sl-color-danger-600);
  border-color:var(--sl-color-danger-600);
  
}
#appHeader #toolbar sl-icon-button::part(base):hover{
  color:var(--sl-color-danger-700);
  border-color:var(--sl-color-danger-700);
}
#appHeader #toolbar sl-icon-button::part(base):active{
  color:var(--sl-color-danger-800);
  border-color:var(--sl-color-danger-800);
}
#appHeader #toolbar #user{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right:1rem;
}
#appHeader #toolbar #user sl-avatar{
  margin-right:1rem;
  --size:2rem;
}
#appHeader .cirrus-logo{
  min-width:3rem;
  height:100%;
  margin-right:1rem;
}
#appHeader .cirrus-logo-image{
  max-width:calc(100% - 2rem);
  max-height:calc(100% - 2rem);
  margin:1rem;
}
/* Add this in your CSS */
.fade-enter,
.fade-exit-done {
  opacity: 0;
}

.fade-exit,
.fade-enter-done {
  opacity: 1;
}

.fade-enter-active,
.fade-exit-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-enter-done {
  transition: none;
}
.wizard,.tasks{
  width:calc(100% - 40px);
  margin:0 auto;
  max-width:80rem;
  min-width:60rem;
}
h1.wizard-title{
  font-size:1.5rem;
  color:var(--sl-color-neutral-500);
  margin-top:4rem;
  font-weight:normal;
  text-transform: uppercase;
  letter-spacing: 0.05em;;
  margin-bottom:1rem;
}
 p.wizard-notes{
  font-size:1rem;
  background:rgba(0,0,0,0.1);
  border: 1px solid var(--sl-color-neutral-100);
  padding:2rem;
  border-radius: 0.5rem;
  width:calc(100% - 6.5rem);
  line-height:1.33rem;
  color:var(--sl-color-neutral-700);
  margin-top:1rem;
  font-weight:normal;
  margin-bottom:3rem;
}

sl-card{
  width:inherit;
  margin-bottom:4rem;
}

#appBody{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width:100vw;
  max-width:100vw;
  overflow-x:hidden;
  height:100%;
  margin:0;
  padding:0;
  box-sizing: border-box;
}

#appFooter{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width:100vw;
  height:4rem;
  /* background-color:rgba(0,0,0,0.1); */
  border-top:1px solid var(--sl-color-neutral-100);
  padding:0 1rem;
  box-sizing: border-box;
  overflow-x:hidden;
  border-left:17rem solid transparent;/*var(--sl-color-neutral-50);*/
  text-align: center;
  color: var(--sl-color-neutral-300);
}
#appFooter p{
  width:100%;
}

 #routeMenu{
    display:flex;
    height:100%;
    width:17rem;
    min-height:100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

#routeContent{
  display:flex;
  width:calc(100vw - 17rem - 2rem);
  height:100%;
  flex:1;
  min-height:calc(100vh - 8rem);
  background:rgba(0,0,0,0.1);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

 #routeMenu nav{
  width:100%;
  height:100%;
  padding:0;
  margin: 0;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

#routeMenu nav ul.nav-menu{
  width:100%;
  height:100%;
  list-style: none;
  padding:0;
  margin:0;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

#routeMenu nav ul li{
  margin:0;
  padding:0;
  width:100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
#routeMenu nav ul li.nav-section-title{
  font-size:0.75rem;
  color:#999;
  margin:0;
  text-align: center;
  width:100%;
  padding:1rem;
  box-sizing: border-box;
  text-transform: uppercase;
}

 #routeMenu nav ul.nav-submenu{
  width:100%;
  height:100%;
  list-style: none;
  padding:0;
  margin:0;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom:2rem;
}

#routeMenu nav ul li a{
  display:block;
  width:100%;
  padding:1.5rem 2rem;
  margin:0;
  text-decoration: none;
  color:inherit;
  
  font-size:0.8rem;
  border-bottom:1px solid var(--sl-color-neutral-200);
  box-sizing: border-box;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
#routeMenu nav ul li a sl-icon{
  margin-left:-0.5rem;
  margin-right:1rem;
  font-size:1.25rem;
}
#routeMenu nav ul li a:hover{
  background-color:rgba(255,255,255,0.1);
  color:#fff;
}
#routeMenu nav ul li a.active{
  background-color:var(--sl-color-primary-400);
  color:#fff;
}
.hidden{
  display:none!important
}
.breadcrumbs{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex:1;
  width:100%;
}
.breadcrumb{
  font-size:1rem;
  padding:0.5rem 1rem;
  color:var(--sl-color-neutral-700);
  flex:1;
  text-align: center;
}
.breadcrumb.active{
  color:var(--sl-color-primary-700);
}
.breadcrumb.valid:after{
  content:" ✓";
}

.uploaded-file-states{
  list-style: none;
  padding:0;
  margin:0;
  display:flex;
  flex-direction: column;
}
.uploaded-file-states li{
  display:flex;
  align-items:center;
  justify-content:flex-start;
  padding:0.5rem;
  border-bottom:1px dashed var(--sl-color-neutral-200);
} 

.uploaded-file-states li:last-child{
  border-bottom:none;
}

.uploaded-file-states li .upload-file-name{
  font-size:0.8rem;
  color:var(--sl-color-neutral-600);
  margin-right:1rem;
  width:80%;
}
.uploaded-file-states li .upload-file-size{
  font-size:0.8rem;
  color:var(--sl-color-neutral-600);
  flex:1;margin-right:1rem;
  width:10%;
}
.uploaded-file-states li .upload-file-status{
  font-size:0.8rem;
  color:var(--sl-color-neutral-600);
  flex:1;
  width:10%;
  text-align: right;
}
.uploaded-file-states li.uploaded .upload-file-status
{
  color:var(--sl-color-success-700);
}
.uploaded-file-states li.uploaded .upload-file-status:after{
  content:" ✓";
}
.uploaded-file-states li.ignored .upload-file-status
{
  color:var(--sl-color-success-700);
}
.uploaded-file-states li.ignored .upload-file-status:after{
  content:" ✓";
}
.uploaded-file-states li.failed .upload-file-status
{
  color:var(--sl-color-danger-700);
}
.uploaded-file-states li.failed .upload-file-status:after{
  content:" ✗";
}

.table,
.table{
  width:100%;
  margin-bottom:1rem;
  display:flex;
  flex-direction: column;
}
.table-cell h3{
  font-size:1rem;
  color:var(--sl-color-neutral-950);
  margin-bottom:2rem;
}
.table .table-row{
  display:flex;
  flex-direction: row;
  align-items:flex-start;
  justify-content:flex-start;
  padding:1rem;
  border-bottom:1px dashed var(--sl-color-neutral-200)
}

/* alternate background */
.table .table-row:nth-child(odd){
  background-color: linear-gradient(90deg, #fff 50%, #f9f9f9 50%);
}
.table .table-row:last-child{
  border-bottom:none;
}
.table .table-cell{
  flex:1;
  margin-right:1rem;
}
.table .table-cell:last-child{
  margin-right:0;
}

.source-selector-preview-map{
  width:320px;
  height:320px;
  max-width:320px;
  max-height:320px;
  min-width:320px;
  min-height:320px;
}
.openlayers-map:not([static]){
  width:100%;
  height:100%;
  max-width:100%;
  max-height:100%;
  min-width:100%;
  min-height:100%;
  border:1px solid var(--sl-color-neutral-300);
    
}
.source-selector-preview-map .openlayers-map{
  width:300px;
  height:300px;
  max-width:300px;
  max-height:300px;
  min-width:300px;
  min-height:300px;
  margin:auto;
}
.import-confirmation-preview-map,
.planning-importer-naming-map{
  width:6rem;
  height:6rem;
  max-width:6rem;
  max-height:6rem;
  min-width:6rem;
  min-height:6rem;
 margin-right:1rem;
}
.import-confirmation-preview-map .openlayers-map,
.planning-importer-naming-map .openlayers-map{
  border:1px solid var(--sl-color-neutral-300);
  width:6rem;
  height:6rem;
  max-width:6rem;
  max-height:6rem;
  min-width:6rem;
  min-height:6rem;
}
.planning-importer-naming .table-cell  h3{
  margin-bottom:1rem;
}

.source-selector-fields{
  display:flex;
  flex-direction:column;
  align-items:flex-end;
  justify-content:space-around;
  margin-bottom:1rem;
  
}



.flex-spacer{
  flex:2;
}

.import-item.table-cell{
  flex:4;
}

.import-checkbox{
 display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.import-checkbox .machine-type,
.import-checkbox .import-name,
.import-checkbox .field-name,
.import-checkbox .import-type{
  margin-right:1rem;
  margin-left:1rem;
  color:var(--sl-color-primary-500);
}


.importer-upload{
  display:flex;
  width:100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom:2rem;
}

.importer-upload input{
  flex:4;
  border:1px solid var(--sl-color-neutral-200);
  background:rgba(0,0,0,0.1);
  border-radius: 0.25rem;
  padding:1rem;
  color:var(--sl-color-neutral-700);
}
/* style the button of ythe native input */
.importer-upload input[type="file"]::-webkit-file-upload-button {
  background:var(--sl-color-neutral-400);
  color:#fff;
  border:none;
  border-radius: 0.25rem;
  padding:1rem;
  cursor:pointer;
  
}

.importer-upload input[type="file"]::-webkit-file-upload-button:hover {
  background:var(--sl-color-neutral-300);
}

.importer-upload input[type="file"]::-webkit-file-upload-button:active {
  background:var(--sl-color-neutral-500);
}

.importer-upload input[type="file"]::-webkit-file-upload-button:focus {
  background:var(--sl-color-neutral-500);
}

.importer-upload sl-button{
  flex:1;
  margin-left:1rem; 
}

.importer-upload sl-button::part(base){
  color:var(--sl-color-neutral-950);
  background:var(--sl-color-neutral-400);
  border-color:var(--sl-color-neutral-400);
}

.importer-upload sl-button::part(base):hover{
  color:var(--sl-color-neutral-950);
  background:var(--sl-color-neutral-300);
  border-color:var(--sl-color-neutral-300);
}

.importer-upload sl-button::part(base):active{
  color:var(--sl-color-neutral-950);
  background:var(--sl-color-neutral-500);
  border-color:var(--sl-color-neutral-500);
}

.common-field-name{
  display:flex;
  width:100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding:1rem;
  margin-bottom:2rem;
}

.common-field-name label{
  margin-right:1rem;
  min-width: 10rem;
}

.common-field-name sl-input{
  flex:1;
}

.naming-item input:read-only{
  background-color:transparent;
  border:none;
  border:0!important;
  padding:0;
  margin:0;
  width:100%;
  color:var(--sl-color-primary-500);
}

.tasks .wizard-title{
  margin-bottom:4rem;
}
table.tasks-table{
  margin-bottom:1rem;
  display:table;
  border-collapse: collapse;
  border-spacing: 0;
  border:none;
  /* table-layout: fixed; */
  width: 100%;
  
}
table.tasks-table thead tr th.table-title{
  font-size:0.9rem;
  letter-spacing: 0.02rem;
  color:var(--sl-color-neutral-600);
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom:1rem;
}

table.tasks-table tbody{
  width: inherit;
  /* display:table-row-group; */
}

table.tasks-table tbody tr{
  width:100%;
}

.tasks-table  th,
.tasks-table  td{
  padding:1rem;
  margin:0;
  text-align:left;
  color:var(--sl-color-neutral-700);
  vertical-align: top;
  border-bottom:1px dashed var(--sl-color-neutral-200);
  user-select: none;
}

.tasks-table  th{
  color:var(--sl-color-neutral-500);
  font-size:0.7rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
}
code.taskid{
  display: inline-block;
  max-width:5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
}
.tasks-table th.id,
.tasks-table td.id{
  /* width:5rem; */
  /* max-width:5rem; */
  text-align:center;
  text-overflow: ellipsis;
  overflow-x: hidden;
  /* display: table-cell; */

  white-space: nowrap;
}

/* .tasks-table td.id{
  direction: rtl;
} */

.tasks-table th.filename,
.tasks-table td.filename{
  /* max-width:15%; */
  font-size:0.75rem;
  text-align:left;
  text-align:left;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.tasks-table td.filename{
  direction: rtl;
}
.tasks-table th.time_start,
.tasks-table td.time_start{
  /* max-width:5%; */
  text-align:center;
}
.tasks-table th.time_end,
.tasks-table td.time_end{
  /* max-width:5%; */
  text-align:center;
}
.tasks-table th.user,
.tasks-table td.user{
  /* max-width:10%; */
  text-align:center;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.tasks-table  th.details,
.tasks-table  td.details{
  /* max-width:30%; */
  text-align:left;
  white-space:wrap;
  word-break: break-all;
}
.tasks-table  td.details p{
  margin:0;
  padding:0;
  font-size:0.8rem;
  
    width:100%;
    white-space: wrap;
}
.tasks-table th.status,
.tasks-table td.status{
  /* max-width:10%; */
  
  text-align:right;
  padding-right: 2rem;
  white-space: nowrap;
}
.tasks-table td.status.completed{
  color:var(--sl-color-success-600);
}
.tasks-table td.status.completed:after{
  content:" ✓";
}
.tasks-table td.status.waiting{
  color:var(--sl-color-warning-600);
}
.tasks-table td.status.waiting:after{
  content:" ⏲";
}
.tasks-table td.status.failed{
  color:var(--sl-color-danger-600);
}
.tasks-table td.status.failed::after{
  content:" ✗";
}
.tasks-table td.status.processing{
  color:var(--sl-color-neutral-400);
}
/* .tasks-table td.status.processing:after{
  content:" ƒ";
} */
/* 
.gpx-source-selector{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:flex-start;
  margin-bottom:1rem;
}
.gpx-source-selector .source-selector{
  display: flex;
  flex-direction: row;  
  align-items: center;
  justify-content: flex-start;
}
.gpx-source-selector .source-selector .source-selector-label{
  font-size:0.8rem;
  color:#666;
  margin-right:1rem;
}
.gpx-source-selector .source-selector .source-selector-preview-map{
  width:320px;
  height:240px;
  border:1px solid #ddd;
  margin-right:1rem;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
