body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding:0;
  
}
h1,h2,h3{
  font-family: 'Barlow', sans-serif;
}

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  width: 8px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color:var(--sl-color-neutral-50);
  -webkit-border-radius: 0;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color:var(--sl-color-neutral-200)
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars. 
     Yes, I pulled out digital color meter */
  background: var(--sl-color-primary-200);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: var(--sl-color-primary-500);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:hover {
  background: var(--sl-color-primary-300);
  -webkit-border-radius: 100px;
}


html{
  height:100%;
  width:100%;
  margin:0;
  padding:0;
  overflow-y: scroll;
}
#root{
  height:100%;
  width:100%;
  margin:0;
  padding:0;
  max-width: 100vw;
  overflow-x: hidden; 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

